export const educationData = [
  {
    id: 1,
    institution: "Masai School, Bengaluru",
    course: "Full Stack Web Development",  //aspiring front-end developer
    startYear: "March 2022",
    endYear: "September 2022",
  },
  {
    id: 2,
    institution: "B.S. College ,Patna",
    course: "Masters of Computer Applications",
    startYear: "2021",
    endYear: "present",
  },
  {
    id: 3,
    institution: "A.N. College ,Patna",
    course: "Bachelors of Computer Applications",
    startYear: "2017",
    endYear: "2020",
  },
];
