export const contactsData = {
  email: "anilkumarsingh142200gmail.com",
  phone: "+917255842110",
  address: "Patna, Bihar",
  sheetAPI: "",
};

// "Request is missing required authentication credential. Expected OAuth 2 access token, login cookie or other valid authentication credential. See https://developers.google.com/identity/sign-in/web/devconsole-project."

// api key = AIzaSyDrBVNualoltTWOI5Mkxp4sAP3c85rnNTE

// client Id = 686410709267-gr273ob93mn6v7qavfhv7oculrc4hscq.apps.googleusercontent.com
// client secret = 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
