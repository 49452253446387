export const socialsData = {
  github: "https://github.com/anil843324",
  // facebook: 'https://www.facebook.com/',
  linkedIn: "https://www.linkedin.com/in/anil-kumar-7311a3211/",
  // instagram: 'https://www.instagram.com/renu.kote',
  // codepen: 'https://codepen.io/',
  // twitter: 'https://twitter.com/KoteRenu',
  // reddit: 'https://www.reddit.com/user/',
  // blogger: 'https://renukote.blogspot.com/',
  // medium: "https://medium.com/@renukaprasad.kb",
  // stackOverflow: 'https://stackoverflow.com/users/',
  // gitlab: 'https://gitlab.com/',
  // youtube: 'https://youtube.com/renukaprasad'
};
