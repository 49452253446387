import ikea_image from "../assets/png/ikea.png";
import colorlib from "../assets/png/Colorlib.jpg";
import TicTacToe from "../assets/png/TicTacToe.jpg";
import TodoList from "../assets/png/TodoList.jpg";
export const projectsData = [
  {

    id: 1,
    projectName: "Tic Tac Toe",
    projectDesc: [
      `A  React Native Cli project and  User helps to play with friends and Easy Bot and Medium Bot .`,
      [
        "User can Play with Friends",
        "User can Play with Easy Bot",
        "User can Play with Medium Bot",
      ],
    ],
    tags: ["Javascript", "React"],
    code: "https://github.com/anil843324/LearningReactNative/tree/master/TicTacToe",
    demo: "NA",
    image: TicTacToe,
  },
  {
    id: 2,
    projectName: "ToDoList",
    projectDesc: [
      ` A  React Native Cli project and  User can create task ,delete , edit and update 👍 .`,
      [
        "User can create task and edit",
        "User can  delete task and check",
        "React navigation Stack and Bottom navigation",
        "Redux integration With Thunk",
        "AsyncStorage",
      ],
    ],
    tags: ["React", "Redux", "Javascript"],
    code: "https://github.com/anil843324/LearningReactNative/tree/master/ToDoList",
    demo: "NA",
    image: TodoList,
  },
  {
    id: 3,
    projectName: "Colorlib Voyage - Clone",
    projectDesc: [
      `A tour management app to book hotels and flights on the go ColorlibVoyage.com .`,
      [
        "Responsive all the landing page",
      ],
    ],
    tags: ["HTML", "CSS", "Javascript"],
    code: "https://github.com/riteshsingla36/colorlib-voyage",
    demo: "http://colorlib-voyage.netlify.app/",
    image: colorlib
  },
  // {
  //   id: 4,
  //   projectName: "firstcry - Clone",
  //   projectDesc: [
  //     `A E-commerce app to Store for newborn, baby & kids products firstcry.com .`,
  //     [
  //       "3 product pages with sorting ",
  //       "Redux integration",
  //       "Cart page features",
  //     ],
  //   ],
  //   tags: ["CSS", "HTML", "Javascript","React","Redux","MaterialUI"],
  //   code: "https://github.com/anil843324/firstcry.com",
  //   demo: "https://firstcry-com.vercel.app/",
  //   image:firstcry
  // },
  // {

];

// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/
