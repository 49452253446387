import resume from "../assets/pdf/resume.pdf";
import profileImg from "../assets/png/Anil.png";

export const headerData = {
  name: "Anil Kumar",
  title: "Web Developer",
  desciption: "Life is 10% what happens to us and 90% how we react to it. ",
  image: profileImg,
  resumePdf: resume,
};

// import resume from "../assets/pdf/resume.pdf";
// import profileImg from "../assets/png/profileImg.png";

// export const headerData = {
//   name: "Renuka Prasad",
//   title: "Web Developer",
//   description:
//     "feet on the ground, eyes at the sky, never stop dreaming, never till you die, spread your wings, and fly high",
//   image: profileImg,
//   resumePdf: resume,
// };
